import { Layout } from 'antd'
import React from 'react'
import {useSelector} from "react-redux";
import { FormattedMessage } from 'react-intl';
import styles from './index.module.less'

const { Footer } = Layout
const CommonFooter = ({ websiteFooter = '' }) => {
  const commonSettingData = useSelector((state) => state.commonSetting.data)
  return (
    <Footer
      className={styles.footerStyle}
    >
      {websiteFooter}
      {commonSettingData.poweredBy=='Y'?(
        <span>
            &nbsp;Powered by&nbsp;
            <a
              href="https://www.infotos.co"
              style={{
                fontSize: 11,
                color: '#9B9B9B',
                textDecoration: 'underline',
              }} target="_blank"
            >
              <FormattedMessage id="poweredBy" />
            </a>
        </span>
        ):''
      }
      
    </Footer>
  )
}
export default CommonFooter
