// 订单结算
import {Form, Modal, InputNumber, Input, Space, Popconfirm, Row, Col} from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import Imgs from 'imgs'
import styles from './index.module.less'
import {
  CheckOutlined,
} from '@ant-design/icons';
import { getSession } from 'utils/Tools'
import {
  toStore as _toStore,
  cancelShoppingCar as _cancelShoppingCar,
  invalidInformation as _invalidInformation,
  toMasterCurrency as _toMasterCurrency,
} from 'reduxModules/customerPhoto'
import Api from 'apis/Api.js'
import myMessage from "../Message";
import AddressModal from '../AddressModal/index'
import ProvinceSelectCascader from "../ProvinceSelectCascader";
import {EmailValidateRule, PhoneValidateRule, RequiredInputRule} from 'utils/ValidateRulesAndMsg'
import {TrimInput, TrimTextArea} from 'components/WithTrim/index'
import addressdata from "../ProvinceSelectCascader/data";
import { FormattedMessage, useIntl } from 'react-intl'


const {payAlipay,payPaypal,payWechat,imgCoupon} = Imgs
const CheckoutModal = ({ data, visible, onCreate, onCancel, payOrders, exHeader, project, setPayCode }) => {
  const [form] = Form.useForm()
  const intl = useIntl()
  const loginUser = JSON.parse(getSession('loginUser')) || {}
  const [loading, setLoading] = useState(false)
  const [canEdit, setCanEdit] = useState('')
  const [checkAddress, setCheckAddress] = useState(null)
  const [pay, setCheckPayWay] = useState({name:'微信支付'})
  const [checkCoupon, setCheckCoupon] = useState({id:null,discountCode:null})
  const [couponPrice, setCheckCouponPrice] = useState(0)
  const [address, setAddress] = useState([

  ])
  const [payWay, setPayWay] = useState([
    {name:'微信支付',img:''},
    // {name:'支付宝支付',img:''},
    // {name:'PayPal支付',img:''},
  ])
  const [order, setOrder] = useState([])
  const [coupon, setCoupon] = useState([])
  const [price, setPrice] = useState(0)
  const [freight, setFreight] = useState(0)
  const [hascoupon, setHascoupon] = useState(0) //可用优惠券
  const [discountCode, setCode] = useState('')
  const [addressVisible, setAddressVisible] = useState(false)
  const [isAddress, setIsAddress] = useState(false)
  const [thatAddress, setThatAddress] = useState({})
  const [transport, setTransport] = useState('') //运输时间
  const [forpay, setForpay] = useState(false) //运输时间

  useEffect(() => {
    if(visible){
      setCheckAddress(null)
      setCheckCoupon({id:null})
      setThatAddress({})
      setIsAddress(false)
      setForpay(false)
    }
  }, [visible])

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true)
        const resData = await onCreate(values)
        setLoading(false)
        if (resData.data && [0].includes(resData.data.code)) {
          form.resetFields()
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }

  const checkPayWay=(id)=>{
    setCheckPayWay(id)
  }
  const checkAddressId=(id)=>{
    setCheckAddress(id)
  }
  const checkCouponId=(v)=>{
    setCheckCoupon(v)
    let p=v.discountType=='sunMoney'?v.discountTypeContent:v.discountType=='percentage'?(price*v.discountTypeContent)/100:v.discountType=='freeMail'?freight:0
    setCheckCouponPrice(p)
    if(v.discountType=='sunMoney'){//优惠金额

    }else if(v.discountType=='percentage'){//优惠百分比

    }else{ //免邮

    }
  }
  useEffect(() => {
    // form.setFieldsValue({
    //   ...data,
    // })
    let freights=0,prices=0
    if(payOrders.length>0){
      payOrders.map(v=>{
        if(!v?.productSpecificationId){
          v.productSpecificationId=v.specificationId
          delete v.specificationId
        }
        if(v.addPageNum>0){ //当加页存在时添加加页价格
          prices += (v.salesPrice + v.pageSalesPrice) * v.number
        }else{
          prices += v.salesPrice * v.number
        }
        freights += v.extraFreight
        v.optionName=v.optionName.replace('-',',')
      })
      setOrder(payOrders)
      setFreight(freights)
      setPrice(prices)
    }
    getCoupon(prices)
    getAddress()
    const now = new Date().getTime()+7*24 *60 * 60 *1000
    let time=new Date(now)
    setTransport((time.getMonth() + 1)+'/'+time.getDate()+'')
  }, [payOrders])
  //获取已兑换优惠券
  const getCoupon=async (prices)=>{
    if(!loginUser.id){return }
    let json={
      account: loginUser.id,
    }
    let data = {
      "method": "GET",
      "url": Api.product.receivedCoupon,
      "parameterJson": JSON.stringify(json),
    }
    const res = await _toStore({data,exHeader})
    if ([0].includes(res.code)) {
      let hcoupon=0
      res.data.map(v=>{
        v.endTime=v.endTime?v.endTime.replace(/-/g,'/').split('T')[0]+intl.formatMessage({id:'cpEndTime'}):intl.formatMessage({id:'cpEndTime2'})
        if(!!v.minPrice&&v.minPrice>prices){
          v.disabled=false
        }else{
          v.disabled=true
          hcoupon++
        }
      })
      let couponList=res.data.filter(v=>{
        return v.disabled
      })
      setHascoupon(hcoupon)
      setCoupon(couponList)
    }else{
      myMessage.warning(res.msg);
    }
  }
  const handleCouponChange=(e)=> {
    setCode(e.target.value)
  }
  //兑换优惠券
  const conversion=async()=>{
    if(!discountCode||discountCode.length!=6){
      myMessage.warning(intl.formatMessage({id:'prompCodeRemindInput'}));
      return
    }
    let json={
      account: loginUser.id,
      redemptionCode:discountCode,
    }
    let data = {
      "method": "POST",
      "url": Api.product.getCoupon,
      "parameterJson": JSON.stringify(json),
    }
    const res = await _toStore({data,exHeader})
    if ([0].includes(res.code)) {
      setCode('')
      getCoupon()
    }else{
      myMessage.warning(res.msg);
    }
  }
  const getAddress=async(ispay)=>{
    if(!loginUser.id){return }
    let json={
      customerId: loginUser.id,
    }
    let data = {
      "method": "GET",
      "url": Api.product.getAddress,
      "parameterJson": JSON.stringify(json),
    }
    const res = await _toStore({data,exHeader})
    if ([0].includes(res.code)) {
      if(res.data.length>0) { //设置默认选中地址
        let defaultFlag = res.data.find(v => v.defaultFlag == 'yes')?.id
        let checkId = defaultFlag?defaultFlag:res.data[0].id
        setCheckAddress(checkId)
      }
      setAddress(res.data)
      if(ispay){ //如果是没有地址直接添加购买
        submitOrder(res.data[0].id)
      }
    }else{
      myMessage.warning(res.msg);
    }
  }
  const onAddress=async(values,ispay)=>{
    let json={
      province:values.areaArray[0],//省
      city:values.areaArray[1],//市
      region:values.areaArray[2],//地区
      address:values.customerAddress,// 收货地址
      customerId:loginUser.id,//用户id
      customerName:values.customerName,//用户名称
      defaultFlag:'no',//默认地址
      district:values.district,//省市区
      email:values.customerEmail,//Email
      nationality:'中国',//国籍
      phoneNum:values.customerPhone,//手机号
    }
    if(!isAddress&&!ispay){json.id=thatAddress.id}
    let data = {
      "method": "POST",
      "url": isAddress||ispay?Api.product.addAddress:Api.product.editAddress,
      "parameterJson": JSON.stringify(json),
    }
    const res = await _toStore({data,exHeader})
    if ([0].includes(res.code)) {
      setAddressVisible(false)
      getAddress(ispay)
    }else{
      myMessage.warning(res.msg);
    }
  }
  const editAddress=async(v)=>{
    setThatAddress(v)
    setIsAddress(false)
    setAddressVisible(true)
  }
  const deleteAddress=async(v)=>{
    let data = {
      "method": "GET",
      "url": Api.product.deleteAddress+'/'+v.id,
      "parameterJson": JSON.stringify(v),
    }
    const res = await _toStore({data,exHeader})
    if ([0].includes(res.code)) {
      getAddress()
    }else{
      myMessage.warning(res.msg);
    }
  }
  const toAddress=()=>{
    setThatAddress({})
    setAddressVisible(true)
    setIsAddress(true)
  }
  const submitOrder = async (addressId) =>{
    //判定是否有收货地址
    if(address.length==0&&!addressId){
      setIsAddress(true)
      setForpay(true)
      form.validateFields()
        .then((values) => {
          if (!values.customerPhone) {
            return
          }
          if(!values.areaArray||values.areaArray.length==0){
            myMessage.warning(intl.formatMessage({id:'ordArsRequired'}))
            return
          }
          const provinceGeoId = values.areaArray[0]
          const cityGeoId = values.areaArray[1]
          const AreaGeoId = values.areaArray[2]
          const provinceData =
            addressdata.find((item) => [provinceGeoId].includes(item.value)) || {}
          // 设置市数据
          const CityOptions = provinceData.children
          const cityData =
            CityOptions.find((item) => [cityGeoId].includes(item.value)) || {}
          // 设置 区 options
          const AreaOptions = cityData.children
          const AreaData=AreaOptions.find((item) => [AreaGeoId].includes(item.value)) || {}
          values.district=provinceData.label+cityData.label+AreaData.label
          form.resetFields()
          onAddress(values,true)
        })
        .catch((info) => {
          console.log('Validate Failed:', info)
        })
      return
    }
    // 不存在收货地址时跳转添加地址页
    if(!checkAddress&&!addressId){
      myMessage.warning(intl.formatMessage({id:'ordDeliveryRequired'}))
      return
    }
    let json = {
      addressId: !!checkAddress?checkAddress:addressId, //收货地址id
      currencyType:'RMB', //货币类型*
      customerId: loginUser.id, //客户id*
      customerName: (loginUser.customerName&&loginUser.customerPhone)?(loginUser.customerName+'，'+loginUser.customerPhone):loginUser.customerPhone||loginUser.customerEmail||'微信用户', //客户名称*
      freight:freight, //运费
      // orderType:wx.getStorageSync("pricesheet").typeId=='4'?'dwnOrder':'printOrder',
      // orderType:payOrders[0].orderType,
      orderType:'printOrder', //订单类型*
      productForms:order, //产品信息 表单*
      productPrice:price, //商品总价
      discountCode:checkCoupon?.discountCode, //优惠券编码
      reliefPrice:couponPrice, //优惠价格
      // reliefPrice:this.data.promotionCode.discountType=='sunMoney'?this.data.promotionCode.discountTypeContent:
      //   this.data.promotionCode.discountType=='percentage'?(this.data.price*this.data.promotionCode.discountTypeContent)/100:
      //     this.data.promotionCode.discountType=='freeMail'?this.data.freight:0, //优惠价格
      sourceItem:'3', //来源项目，3表示C端Web
      remarks:canEdit,//订单备注
      storeId:project.enterpriseId, //商家id
      // supplierId:this.data.orderList[0].supplierEnterpriseId?this.data.orderList[0].supplierEnterpriseId:wx.getStorageSync("pricesheet").supplierEnterpriseId,
      totalMoney:(price+freight-couponPrice)>0?price+freight-couponPrice:0, //合计支付*
      customerPhone:loginUser?.customerPhone, //客户手机号
    }
    let data = {
      method: 'POST',
      parameterJson: JSON.stringify(json),
      url: Api.product.addOrder
    }
    let res=await _toStore({data,exHeader})
    if ([0].includes(res.code)) {
      payOrder(res.data)
    }else{
      myMessage.warning(res.msg);
    }
  }

  const payOrder = async (v) => {
    let openid = {
      //   openid: wx.getStorageSync('openId')
      // openid: ''
    }
    let json = {
      // appid:'wx0e2aefa2f0045f55',
      appid: 'wx0e8580e0e3dcd330',
      // attach: 'myorders',
      orderId: v.id,
      payer: openid,
      tradeType: 'NATIVE',
    }
    let data = {
      method: 'POST',
      parameterJson: JSON.stringify(json),
      url: Api.product.payorder
    }
    let res=await _toStore({data,exHeader})
    if ([0].includes(res.code)) {
      setPayCode(res.data.payInfo,v)
      //提交订单删除购物车
      if(!order[0].freightPrice){
        let ids=[]
        order.map(v=>{
          ids.push(v.id)
        })
        let data={
          ids:ids
        }
        _cancelShoppingCar({data,exHeader})
      }
      //购买后删除编辑中
      order.map(v=>{
        let data = {
          id:v.exportId
        }
        _invalidInformation({data,exHeader})
      })
    }else{
      myMessage.warning(res.msg);
    }
  }
  return (
    <Modal
      visible={visible}
      title={<FormattedMessage id="checkout" />}
      okText={<FormattedMessage id="save" />}
      cancelText={<FormattedMessage id="cancel" />}
      footer={null}
      onCancel={onCancel}
      onOk={onOk}
      confirmLoading={loading}
      maskClosable={false}
      width={980}
      centered
    >
      <div className={styles.checkout}>
        <div className={styles.left}>
          {address.length>0?(<div className={styles.address}>
            <div className={styles.title}><FormattedMessage id="orderDeliveryDetails" /></div>
            <div className={styles.addressList}>
              {address.map(v=>{
                return (
                  <div className={`${styles.people} ${checkAddress===v.id?styles.green:''}`}>
                    <div className={styles.ress}>
                      <div className={styles.ress}>{v.customerName}, {v.phoneNum}</div>
                      <div className={styles.ress}>{v.district}{v.address} {v.nationalCode}</div>
                    </div>
                    <div className={styles.updateRess}>
                      <span onClick={() => editAddress(v)}><FormattedMessage id="ordArsUpdate" /></span>
                      <span className={styles.splitLine}>|</span>
                      <Popconfirm
                        title={<FormattedMessage id="ordArsDialogDelete" />}
                        onConfirm={() => deleteAddress(v)}
                        okText={<FormattedMessage id="ok" />}
                        cancelText={<FormattedMessage id="cancel" />}
                      >
                         <span><FormattedMessage id="ordArsDelete" /></span>
                      </Popconfirm>
                    </div>
                    <div onClick={()=>checkAddressId(v.id)} className={`${styles.check} ${checkAddress===v.id?styles.checked:''}`}>
                      {checkAddress===v.id&&(<CheckOutlined />)}
                    </div>
                  </div>
                )
              })}
            </div>
            <div className={styles.addAddress} onClick={toAddress}><FormattedMessage id="ordNewAddress" /></div>
          </div>):(
            <Form
                form={form}
                layout="vertical"
                name="add_project_form"
                requiredMark={false}
                className={styles.addressModal}
                destroyOnClose={true}
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      name="customerName"
                      label={<FormattedMessage id="orderRecipient" />}
                      rules={[
                        {
                          required: true,
                          message:  (<FormattedMessage id="required" />),
                        },
                      ]}
                    >
                      <Input placeholder={intl.formatMessage({ id: 'needInput'})} maxLength={30}/>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="customerPhone"
                      label={<FormattedMessage id="ordArsPhone" />}
                      rules={[{
                        required: true,
                        message: (<FormattedMessage id="required" />),
                      },PhoneValidateRule(intl)]}
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="customerEmail"
                      label="Email"
                      rules={[EmailValidateRule(intl)]}
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name=""
                      label={<FormattedMessage id="ordAddress" />}
                    >
                      <Form.Item
                        label=""
                        name="areaArray"
                        className={styles.Inf_ant_form_item}
                      >
                        <ProvinceSelectCascader width100={true}/>
                      </Form.Item>
                      <Form.Item
                        style={{display: 'inline-block'}}
                        label=""
                        name="customerAddress"
                        rules={[RequiredInputRule(intl)]}
                      >
                        <TrimInput style={{width: '500px'}} placeholder={intl.formatMessage({ id: 'ordAddressDetail'})}/>
                      </Form.Item>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
          )}

          <div className={styles.payWay}>
            <div className={styles.title}><FormattedMessage id="checkoutPayWay" /></div>
            <div className={styles.payList}>
              {payWay.map(v=>{
                return(
                  <div className={`${styles.pays} ${pay.name==v.name?styles.checkPay:''}`} onClick={()=>checkPayWay(v)}>
                    <img src={payWechat}/>
                    <div>{v.name}</div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className={styles.time}>
            <div className={styles.title}><FormattedMessage id="checkoutPdTime" /></div>
            <div className={styles.send}><FormattedMessage id="shipDateRemind1" />{transport}<FormattedMessage id="shipDateRemind2" /></div>
          </div>
          <div className={styles.remark}>
            <div className={styles.title}><FormattedMessage id="orderRemark" /></div>
            <TrimTextArea
              autoSize
              maxLength={200}
              onBlur={(e) => setCanEdit(e.target.value)}
              placeholder={intl.formatMessage({ id: 'checkoutInputMark'})}
            />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.order}>
            <div className={styles.title}><FormattedMessage id="checkoutOrderInfo" /></div>
            <div className={styles.orderList}>
              {order.map(v=>{
                return(
                  <div className={styles.orders}>
                    <div className={styles.img}><img src={v.imageUrl} alt=""/></div>
                    <div className={styles.info}>
                      <div className={styles.title}>{v.productName}</div>
                      <div className={styles.price}>{v.optionName}</div>
                      <div className={styles.number}>×{v.number}</div>
                    </div>
                    <div className={styles.orderPrice}>¥{Number(v.number)*Number(v.price)}</div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className={styles.coupon}>
            <div className={styles.title}><FormattedMessage id="checkoutCoupon" /></div>
            <div className={styles.convertibility}>
                <Input placeholder={intl.formatMessage({ id: 'checkoutInputCode'})} value={discountCode} onChange={handleCouponChange}/>
                <div onClick={()=>conversion()} className={styles.exchange}><FormattedMessage id="checkoutCpExchange" /></div>
            </div>
            <div className={styles.couponlist}>
              {coupon.map(v=>{
                return(
                  <div className={styles.coupons}>
                    <div className={styles.img}><img src={imgCoupon}/></div>
                    <div className={styles.info}>
                      <div className={styles.title}>{v.discountName}</div>
                      <div className={styles.des}><FormattedMessage id="cpMiniPrice" />{v.minPrice}{v.discountType!='freeMail'?<FormattedMessage id="cpDiscount" />:<FormattedMessage id="cpFreeMail" />}{v.discountTypeContent?v.discountTypeContent:''}{v.discountType=='sunMoney'?'元':v.discountType=='percentage'?'%':''}<FormattedMessage id="cpDiscount2" />{v.endTime}</div>
                    </div>
                    <div onClick={()=>checkCouponId(v)} className={`${styles.check} ${checkCoupon.id===v.id?styles.checked:''}`}>
                    {checkCoupon.id===v.id&&(<CheckOutlined />)}
                  </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className={styles.bottomPrice}>
            <div className={styles.total}>
              <div className={styles}><FormattedMessage id="cartSubtotal" /></div>
              <div className={styles.price}>¥{price}</div>
            </div>
            <div className={styles.freight}>
              <div className={styles.text}><FormattedMessage id="orderFreight" /></div>
              <div className={styles.price}>¥{freight}</div>
            </div>
            <div className={styles.discounts}>
              <div className={styles.text}><FormattedMessage id="orderDiscount" /></div>
              <div className={styles.price}>-¥{couponPrice}</div>
            </div>
            <div className={styles.payment}>
              <div className={styles.text}><FormattedMessage id="orderTotal" /></div>
              <div className={styles.price}>¥{(price+freight-couponPrice)>0?price+freight-couponPrice:0}</div>
            </div>
          </div>
          <div className={styles.newfooter}>
            <div className={styles.submit} onClick={()=>submitOrder()}><FormattedMessage id="checkoutSubmit" /></div>
          </div>
        </div>
        <AddressModal
          visible={addressVisible}
          isAddress={isAddress}
          thatAddress={thatAddress}
          onCreate={onAddress}
          onCancel={() => {
            setAddressVisible(false)
          }}
          clientInfo={{}}
        />
      </div>
    </Modal>
  )
}


export default CheckoutModal
