import {Button, Col, Divider, Dropdown, Form, Input, Menu, Popover, Row, Spin, Tooltip, Image, Collapse, Modal } from 'antd'
import BottomMessage from 'components/BottomMessage/index'
import download from 'downloadjs'
import Masonry from 'masonry-layout'
import React from 'react'
import { FormattedMessage,useIntl } from 'react-intl'
import InfiniteScroll from 'react-infinite-scroller'
import styles from './store.module.less'
import {isNil} from "lodash";
import Imgs from 'imgs'
import Api from 'apis/Api.js'
import {
  getSessionStorageValue,
} from 'utils/Help'
import {
  PlusOutlined,
  LineOutlined,
  ArrowLeftOutlined,
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons';
import {
  getProductsInfo as _getProductsInfo,
  toStore as _toStore,
  getProject as _getProject,
  getPolicy as _getPolicy,
  getInformation as _getInformation,
  toMod as _toMod,
  addShoppingCar as _addShoppingCar,
  addInformation as _addInformation,
  addAblum as _addAblum,
} from 'reduxModules/customerPhoto'
import myMessage from "../../../Components/Message";
import {CopyToClipboard} from "react-copy-to-clipboard";

const {TextArea} = Input
const { Panel } = Collapse
export default class Store extends React.Component {
  formRef = React.createRef()
  state = {
    productData: [],
    hasMore: true,
    contentVisible: false,
    selectedImg: {},
    idOfShowOpts: '',
    previewCarouselModalVisible: false, // 查看图片弹窗
    previewList: [], // 查看弹窗图片list
    previewCurrent: 0, // 查看弹窗当前siderNumber
    pagination: {
      current: 1,
    }, // 分页数据
    loading: true,
    favVisible: false,
    problem:[],
    active:null,
    showIndex:'projectList',
    projectInfo:{},
    projectInfoShowImg:0,
    maybeLikeProducts:[],
    showPrice:0,
    showQrCode:false, //是否显示小程序二维码
    information:[
      // {id:1,coverImageUrl:'',title:'20页 · 21×21cm等选项值',updateTime:'2024-05-09 20:09'},
      // {id:2,coverImageUrl:'',title:'20页等选项值',updateTime:'2023-01-10 20:09'},
    ],//编辑中的
    informationVisible:false,
    checkInformation:0,
    specification:null,//选择的规格
    opener:null,//页面跳转
    pushCoupon:null,
  }

  componentDidMount() {
    // const { categoryId, callbackObj, setCallbackObj } = this.props
    // setCallbackObj({
    //   ...callbackObj,
    //   callbackFuncs: {
    //     ...callbackObj.callbackFuncs,
    //     ...this,
    //   },
    // })
    this.getProducts()
    this.getInformation()
    this.getPushCoupon()
  }
  //获取推荐优惠券
  getPushCoupon=async ()=>{
    const {exHeader, project, intl} = this.props
    let json={
      storeId : project.enterpriseId,
    }
    let data = {
      "method": "POST",
      "url": Api.product.getPushCoupon+'/'+json.storeId ,
      "parameterJson": JSON.stringify(json),
    }
    const res = await _toStore({data,exHeader})
    if ([0].includes(res.code)) {
      if(res.data){
        res.data.endTime=res.data.endTime?res.data.endTime.replace(/-/g,'/').split('T')[0]+intl.formatMessage({id:'cpEndTime'}):intl.formatMessage({id:'cpEndTime2'})
        this.setState({pushCoupon: res.data})
      }
    }
  }
  //获取当前产品存在编辑中的数量
  getInformation =async()=>{
    const {_pagePhotos, exHeader, backToLogin,project} = this.props
    let data = {
      id: project.id
    }
    const res = await _getInformation({data,exHeader})
    if ([0].includes(res.code)) {
      let newEditing=[]
      res.data.map( async v=>{
        // v.coverImageUrl=v.coverImageUrl+'?'+Math.random()
        v.coverImageUrl=`http://exports.infotos.co/covers/${v.serialNumber}.jpg`
        let json={
          exportId:v.exportId
        }
        let data = {
          "method": "GET",
          "url": Api.product.getExportInfo,
          "parameterJson": JSON.stringify(json),
        }
        let re=await _toMod({data,exHeader})
        console.log(re.data)
        if ([0].includes(re.code)) {
          v.pro = re.data
          if (re.data?.described) {
            v.title = JSON.parse(re.data?.described)?.titleName
            // console.log(JSON.parse(re.data?.described),JSON.parse(re.data?.described).coverCheckLayout)
          }
          v.updateTime = re.data.updatedTime.split('.')[0].replace('T', ' ')
          v.updateTime = v.updateTime.slice(0, v.updateTime.length - 3)
          newEditing.push(v)
          // newEditing.sort(function(a,b){
          //   return GetDate.getUnixTime(b.updateTime)-GetDate.getUnixTime(a.updateTime)
          // })
          this.setState({information: newEditing})
        }
      })

    }
  }
  //获取产品列表
  getProducts = async () => {
    const {_pagePhotos, exHeader, backToLogin,project} = this.props
    console.log(project)
    let json={
      id: project.priceId,
      storeId: project.enterpriseId
    }
    let data = {
      "method": "POST",
      "url": Api.product.pricesheetInfo,
      "parameterJson": JSON.stringify(json),
    }
    const res = await _toStore({data,exHeader})
    if ([0].includes(res.code)) {
      res.data.map((item,i)=>{
        item.productBaseInfoList=item.productBaseInfoList.filter(val=>{
          if (!!val.productOptionVos) {
            val.productOptionVos && val.productOptionVos.map((v, i) => {
              v.options = []
              v.optionValue.split(',').map(va => {
                v.options.push({
                  value: va,
                  isDisplay: false
                })
              })
              v.text = v.optionValue.split(',')[0]
              v.value = 0
            })
          }
          if(!val.salesPrice){
            if(val.productSpecificationsVos&&val.productSpecificationsVos.length>0) {
              val.salesPrice=val.productSpecificationsVos[0].salesPrice
              val.productSpecificationsVos.map(v=>{
                val.salesPrice=val.salesPrice>v.salesPrice?v.salesPrice:val.salesPrice
              })
            }else{
              val.salesPrice=val.price
            }
          }
          return val.status=='online'
        })
      })
      this.setState({ productData: res.data })
    } else if ([9200].includes(res.code)) {
      backToLogin()
    }
    this.getPolicy()
  }
  //返回操作
  back=(item)=>{
    this.setState({ showIndex:item })
  }
  // 获取问题列表
  getPolicy=async()=>{
    const {_pagePhotos, exHeader, backToLogin,project} = this.props
    let json={
      // id: project.priceId,
      // storeId: project.id,
      pageNum: 1,
      pageSize:10,
      storeId: project.enterpriseId
    }
    let data = {
      "method": "POST",
      "url": Api.product.getPolicy,
      "parameterJson": JSON.stringify(json),
    }
    const res = await _toStore({data,exHeader})
    // console.log(res.data.records)
    if ([0].includes(res.code)) {
      this.setState({ problem: res.data.records })
    } else if ([9200].includes(res.code)) {
      backToLogin()
    }
  }
  //选择展示的产品
  toProducts = (item) => {
    this.setState({ showIndex:'projectInfo',projectInfo:item })
  }
  // 常见的问题展示
  clickProblem=(key)=>{
    this.setState({active:key==this.state.active?'':key})
  }
  // 产品详情
  toProjectInfo=(project)=>{
    //滚动到顶部
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    let other=[],showother=[];
    this.state.productData.map(v=>{
      if(v.id!=project.typeId){
        other.push(...v.productBaseInfoList)
      }else{
        v.productBaseInfoList.map(va=>{
          if(va.id!=project.id&&showother.length<3) {
            showother.push(va)
          }
        })
      }
    })
    let hasLength=3-showother.length
    let ind = Math.floor(Math.random() * (other.length-2))
    showother.push(...other.splice(ind,hasLength))
    // console.log(project.processDays,project.paperBinding)
    this.setState({showIndex:'projectInfo',projectInfo:project,maybeLikeProducts:showother})
    this.setPrice(project)
  }
  //选择图片大图展示
  checkImg=(i)=>{
    this.setState({projectInfoShowImg:i})
  }
  // 选择产品规格
  checkOpt=(val,optId,ind)=> {
    let item=JSON.parse(JSON.stringify(this.state.projectInfo))
    if(item.typeId!=5) {
      item.productOptionVos.map(v => {
        if (v.id == optId) {
          v.text = val.value
          v.value = ind
        }
      })
    }else{}
    this.setState({projectInfo: item})
    this.setPrice(item)
  }
  //设置选项的价格
  setPrice=(item)=>{
    let optionName='',chekOpt=[]
    if(item.productOptionVos&&item.productOptionVos.length>0) {
      item.productOptionVos.map(v => {
        chekOpt.push(v.text)
      })
      optionName = chekOpt.join(',')
      let Specificat=item.productSpecificationsVos.find(v => v.optionName.replace('页', '') == optionName)
      this.setState({showPrice: Specificat?.salesPrice,specification:Specificat})
    }else{
      this.setState({showPrice: item?.salesPrice})
    }
  }
  //关闭弹框 开始定制
  showqr=async ()=>{
    const {exHeader,getShoppingCarList,project,intl,showCart} = this.props
    let projectInfo=this.state.projectInfo
    let data={
      productId:projectInfo.id,//商品ID
      name:projectInfo.name,//导出名称
      described:projectInfo.described,//描述信息
      coverImage:'',//导出封面图片
    }
    /*let specification = this.state.specification
    let optionName = []
    projectInfo.productOptionVos.map(v => {
      optionName.push(v.optionName == "albumPages" ? v.text + '页' : v.optionName == 'pages' ? v.text + 'pages' : v.text)
    })
    data={
      photoId: projectInfo.imageId.split(',')[0],//购买图片ID
      priceId: project.priceId,//价格表ID
      productId: projectInfo.id,//商品ID
      specificationId: specification.id,//商品规格ID
      // exportId: re.data.exportId,//导出信息ID
      // serialNumber: re.data.exportId,//导出信息ID
      name: projectInfo.name,//商品名称
      number: 1,//商品数量
      described: projectInfo.describe,//商品描述信息
      optionName: optionName.join(', '),//订单规格信息
      price: specification.salesPrice,//单价
      freightPrice: projectInfo.typeId == 4 ? 0 : !projectInfo.productOptionVos ? projectInfo.extraPostage : specification.extraFreight,//运输价格
      pageSalesPrice: specification.pageSalesPrice,//加页价格
      addPageNum: 0,//加页数量
      imageId: !!specification.imageId ? specification.imageId : projectInfo.imageId.split(',')[0],//图片ID
      // imageUrl:project.typeId == 4 ? project.imageId.split(',')[0] : !!specification.imageId ? specification.imageUrl : specification.imageUrl[0],//图片地址
      imageUrl: projectInfo.imageUrls[0],//图片地址
      orderType: projectInfo.typeId == '4' ? 'dwnOrder' : 'printOrder',//订单类型
      storeId: projectInfo.storeId,//商家ID
      supplierId: projectInfo.supplierEnterpriseId,//供应商ID
      productTypeId: projectInfo.typeId,//产品类型ID
      pageControlType: projectInfo.pageControlType,//页数控制方式, freeIncrease:自由增页，fixedPage：固定页数
    }
    this.state.opener = window.open(`${Api.toBC}/project/page`,'_blank');
    // 监听 message 的变化
    window.addEventListener('message', (e) => {
      if (e.origin === `${Api.toBC}`) { // 接收来自 http://127.0.0.1:5173 网页的信息
        if(e.data?.link) {
          let message = JSON.stringify({projectInfo, project, data,token:sessionStorage.getItem('token')})
          console.log(message)
          setInterval(()=>{this.state.opener.postMessage(message, `${Api.toBC}`);},2000)
          // this.state.opener.postMessage(message, `${Api.toBC}`);  // 此时才给 B 传输信息
        }

        // document.getElementById('span1').innerHTML = e.data // 作为第一个参数传递给postMessage的数据
        // document.getElementById('span2').innerHTML = e.origin // 发送消息的来源，可以根据origin来确保期望的发送者
        // document.getElementById('span3').innerHTML = e.source // 发送消息的window代理对象
      }
    })
    return*/
    let re=await _addInformation({data,exHeader})
    if ([0].includes(re.code)) {
      let specification = this.state.specification
      let optionName = []
      projectInfo.productOptionVos.map(v => {
        optionName.push(v.optionName == "albumPages" ? v.text + '页' : v.optionName == 'pages' ? v.text + 'pages' : v.text)
      })
      data = {
        photoId: projectInfo.imageId.split(',')[0],//购买图片ID
        priceId: project.priceId,//价格表ID
        productId: projectInfo.id,//商品ID
        specificationId: specification.id,//商品规格ID
        exportId: re.data.exportId,//导出信息ID
        serialNumber: re.data.exportId,//导出信息ID
        name: projectInfo.name,//商品名称
        number: 1,//商品数量
        described: projectInfo.describe,//商品描述信息
        optionName: optionName.join(', '),//订单规格信息
        price: specification.salesPrice,//单价
        freightPrice: projectInfo.typeId == 4 ? 0 : !projectInfo.productOptionVos ? projectInfo.extraPostage : specification.extraFreight,//运输价格
        pageSalesPrice: specification.pageSalesPrice,//加页价格
        addPageNum: 0,//加页数量
        imageId: !!specification.imageId ? specification.imageId : projectInfo.imageId.split(',')[0],//图片ID
        // imageUrl:project.typeId == 4 ? project.imageId.split(',')[0] : !!specification.imageId ? specification.imageUrl : specification.imageUrl[0],//图片地址
        imageUrl: projectInfo.imageUrls[0],//图片地址
        orderType: projectInfo.typeId == '4' ? 'dwnOrder' : 'printOrder',//订单类型
        storeId: projectInfo.storeId,//商家ID
        supplierId: projectInfo.supplierEnterpriseId,//供应商ID
        productTypeId: projectInfo.typeId,//产品类型ID
        pageControlType: projectInfo.pageControlType,//页数控制方式, freeIncrease:自由增页，fixedPage：固定页数
      }
      // shopping/addShoppingCar
      let res = await _addShoppingCar({data, exHeader})
      if ([0].includes(res.code)) {
        getShoppingCarList()
        showCart()//添加后弹出购物车
        myMessage.success(intl.formatMessage({ id: 'cartAddSucceeded' }));
      }else{
        myMessage.warning(res.msg);
      }
    }else{
      myMessage.warning(re.msg);
    }
    return
    this.setState({showQrCode:true})
  }
  hideqr=()=>{
    this.setState({showQrCode:false})
  }
  //打开编辑中弹框
  showInformation=()=>{
    this.setState({informationVisible:true})
  }
  hideInformation=()=>{
    this.setState({informationVisible:false})
  }
  up=()=>{
    if(this.state.checkInformation>0){
      this.setState({checkInformation:this.state.checkInformation-1})
    }
  }
  next=()=>{
    if(this.state.checkInformation<this.state.information.length-1){
      this.setState({checkInformation:this.state.checkInformation+1})
    }
  }
  render() {
    const {
      loading,
      productData,
      text,
      active,
      problem,
      showIndex,
      projectInfo,
      projectInfoShowImg,
      maybeLikeProducts,
      showPrice,
      showQrCode,
      information,
      informationVisible,
      checkInformation,
      pushCoupon,
    } = this.state
    const {} = this.props
    return <>
      {<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
        {/*<Spin spinning={loading} tips={<FormattedMessage id="getData" />} />*/}
      </div>}
      {(showIndex==='projectList')?(
        // 印坊列表页
        <div className={styles.projectList}>
          {pushCoupon?.id&&(
            <div className={styles.couponBlock}>
              <div className={styles.text}>
                  <div className="iconfont iconico_coupon_2"><span>{pushCoupon.discountName}: <FormattedMessage id="cpMiniPrice" />{pushCoupon.minPrice}{pushCoupon.discountType!='freeMail'?<FormattedMessage id="cpDiscount" />:<FormattedMessage id="cpFreeMail" />}{pushCoupon.discountTypeContent}{pushCoupon.discountType=='sunMoney'?'元':pushCoupon.discountType=='percentage'?'%':''}<FormattedMessage id="cpDiscount2" /><FormattedMessage id="cpCoupnCode" /><span className={styles.couponCode}>{pushCoupon.redemptionCode}</span><FormattedMessage id="cpCoupnCode2" />{pushCoupon.endTime}</span>
                    <CopyToClipboard text={pushCoupon.redemptionCode}>
                      <Tooltip title={<FormattedMessage id="cpCopied" />} trigger={['click']}>
                        <span class="icon iconfont" className={styles.copy}>&#xe72f;</span>
                      </Tooltip>
                    </CopyToClipboard>
                  </div>
              </div>
              {/* <img src={Imgs.storeCoupon}/> */}
            </div>
          )}
          {(productData.length > 0 &&
            <div>
              {productData.map((item, key) => {
                return (
                  <div className={styles.products}>
                    {item.productBaseInfoList.length>0&&(<div className={styles.productCategory}>{item.productTypeName}</div>)}
                    <Row gutter={[30, 10]}>
                      {item.productBaseInfoList.map((v, k) => {
                        return (
                          <Col
                            key={v.id}
                            onClick={() => this.toProducts(v)}
                            xs={12}
                            sm={12}
                            md={8}
                            lg={8}
                            xl={8}
                            // xxl={4}
                          >

                            <div className={styles.list} onClick={()=>this.toProjectInfo(v)}>
                              <div className={styles.img}>
                                <img src={v.imageUrls[0]} alt=""/>
                              </div>
                              <div className={styles.itemName}>{v.name}</div>
                              <div className={styles.itemPrice}>￥{v.salesPrice}<FormattedMessage id="priceStart" /></div>
                            </div>
                          </Col>
                        )
                      })}

                    </Row>
                  </div>
                )
              })}
            </div>
          )}
          <div className={styles.questionsBlock}><FormattedMessage id="storeFaq" /></div>
          {(problem.length>0&&
            <div>
              {problem.map((item, key) => {
                return (
                  <div className={styles.problem}>
                    {item.id==active?(
                      <div className={styles.noBorder} onClick={()=>this.clickProblem(item.id)}>
                        <span>{item.name}</span>
                        {item.id!=active?(<span className="iconfont iconiconClosed"></span>):(<span className="iconfont iconiconOpened"></span>)}
                      </div>
                    ):(
                      <div className={styles.questions} onClick={()=>this.clickProblem(item.id)}>
                        <span>{item.name}</span>
                        {item.id!=active?(<span className="iconfont iconiconClosed"></span>):(<span className="iconfont iconiconOpened"></span>)}
                      </div>
                    )}
                    {(item.id==active&&
                      <div className={styles.answer}>{item.describe}</div>
                    )}
                  </div>
                )
              })}
            </div>)}
        </div>):showIndex==='projectInfo'?(
          // 产品详情
          <div className={styles.projectInfo}>
            <div className={styles.forBack} onClick={()=>this.back('projectList')}>
              <div><ArrowLeftOutlined /><FormattedMessage id="storeBack" /></div>
            </div>
            <Row className={styles.project} gutter={30}>
              <Col
                xs={24}
                sm={24}
                md={13}
                lg={13}
                xl={13}
                className={styles.imgs}>
                <div className={styles.bigImg}>
                  <img src={projectInfo?.imageUrls[projectInfoShowImg]} alt=""/>
                </div>
                <ul>
                  {projectInfo?.imageUrls.map((item, i) => {
                    return (
                        <li onClick={()=>this.checkImg(i)}>
                          <img src={item} alt=""/>
                        </li>
                      )}
                   )}
                </ul>
              </Col>
              <Col xs={24}
                   sm={24}
                   md={11}
                   lg={11}
                   xl={11} className={styles.info}>
                <div className={styles.name}>{projectInfo?.name}</div>
                <div className={styles.price}>￥{showPrice}</div>
                {information.length>0&&(
                  <div className={styles.information} onClick={()=>this.showInformation()}>{information.length}个设计编辑中</div>
                )}
                <div className={styles.productOverview}>{projectInfo?.productOverview}</div>
                {projectInfo.typeId!=5&&projectInfo.typeId!=7&&projectInfo?.productOptionVos.length>0&&projectInfo?.productOptionVos.map((item, i) => {
                  return (
                    <div className={styles.options}>
                      {(item.optionName=='albumPages')?(<div className={styles.opt}><FormattedMessage id="albumPages" /></div>):(<div className={styles.opt}>{item.optionName}</div>)}
                      <ul>
                        {item?.options.length>0&&item?.options.map((val,ind)=>{
                          return(
                            <li className={item.value==ind?styles.active:''} onClick={()=>this.checkOpt(val,item.id,ind)}>{val.value}</li>
                          )
                        })}
                      </ul>
                    </div>
                  )
                })}
                {projectInfo.typeId==5&&(
                  <div className={styles.options}>
                    <div className={styles.opt}>产品</div>
                    <ul>
                      {projectInfo?.productSetVos.length>0&&projectInfo?.productSetVos.map((item, i) => {
                        return(
                          <li className={item.value==i?styles.active:''} onClick={()=>this.checkOpt(item,item.id,i)}>{item.childProductName}</li>
                        )
                      })}
                    </ul>
                  </div>
                )}
                <div className={styles.customization} onClick={()=>this.showqr()}><FormattedMessage id="startDesign" /></div>
                {projectInfo?.describe&&(<div className={styles.textTitle}><FormattedMessage id="pdDescription" /></div>)}
                <div className={styles.description}>{projectInfo?.describe}</div>
                {projectInfo?.paperBinding&&(<div className={styles.textTitle}><FormattedMessage id="paperBinding" /></div>)}
                <div className={styles.description}>{projectInfo?.paperBinding}</div>
                {projectInfo?.processDays&&(<div className={styles.textTitle}><FormattedMessage id="prcessDays" /></div>)}
                <div className={styles.description}>{projectInfo?.processDays}</div>
              </Col>
            </Row>
            <div className={styles.maybeLike}>
              <div className={styles.title}><FormattedMessage id="maybeLike" /></div>
              <Row gutter={[30, 10]}>
                {maybeLikeProducts.length>0&&maybeLikeProducts.map((v, k) => {
                  return (
                    <Col
                      key={v.id}
                      onClick={() => this.toProducts(v)}
                      xs={12}
                      sm={12}
                      md={8}
                      lg={8}
                      xl={8}
                      // xxl={4}
                    >

                      <div className={styles.list} onClick={()=>this.toProjectInfo(v)}>
                        <div className={styles.img}>
                          <img src={v.imageUrls[0]} alt=""/>
                        </div>
                        <div className={styles.itemName}>{v.name}</div>
                        <div className={styles.itemPrice}>￥{v.price}<FormattedMessage id="priceStart" /></div>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </div>
            {/* <Modal title="暂不支持直接定制"
                   visible={showQrCode}
                   centered
                   footer={<div></div>}
                   width={600}
                   closable={false}
            >
              <div className={styles.qr}>
                <img src={Imgs.Wxcode} alt=""/>
                <div className={styles.qrtext}>具体请微信扫描二维码添加客服咨询处理</div>
                <div className={styles.qrok} onClick={()=>this.hideqr()}>知道了</div>
              </div>
            </Modal> */}
            <Modal title={`存在${information.length}个编辑中的作品`}
                   visible={informationVisible}
                   centered
                   footer={null}
                   onCancel={()=>this.hideInformation()}
                   width={600}
            >
              <div className={styles.informationModal}>
                <div className={styles.informat}>
                  <div className={styles.left} onClick={()=>this.up()}><LeftOutlined /></div>
                  <div className={styles.center}>
                    <div className={styles.img}>
                      <img src="" alt=""/>
                    </div>
                  </div>
                  <div className={styles.right} onClick={()=>this.next()}><RightOutlined /></div>
                </div>
                <div className={styles.informatTitle}>{information[checkInformation]?.title}</div>
                <div className={styles.time}>最近更新于{information[checkInformation]?.updateTime}</div>
                <div className={styles.button}>
                  <div className={styles.addInformation}>创建新的</div>
                  <div className={styles.edit}>继续编辑</div>
                </div>
              </div>
            </Modal>
          </div>
      ):(
        <div></div>
      )}
    </>
  }
}
