import { Select } from 'antd'
import { isArray, isNil } from 'lodash/fp'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import data from './data.json'
import styles from './index.module.less'

const ProvinceSelectCascader = (props) => {
  const intl = useIntl()
  const { value = [], onChange, width100 } = props

  // value
  const [cascaderValue, setCascaderValue] = useState([])

  // 市 options
  const [citys, setCitys] = useState([])

  // 区 options
  const [areas, setAreas] = useState([])

  const triggerChange = (changedValue) => {
    if (onChange && changedValue.length >= 3) {
      onChange(changedValue)
    } else {
      onChange([])
    }
  }
  // 省
  const onProvinceChange = (value, option) => {
    let changedValue = []
    let CityOptions = []
    if (!isNil(value)) {
      CityOptions = option.children
      changedValue = [value]
    }
    // 设置 市 options
    setCitys(CityOptions)
    // 清空 区 options
    setAreas([])
    // 设置 cascaderValue
    setCascaderValue(changedValue)
    triggerChange(changedValue)
  }
  // 市
  const onCityChange = (value, option) => {
    let changedValue = []
    let AreaOptions = []
    if (!isNil(value)) {
      // 设置 区 options
      AreaOptions = option.children
      // 设置市数据
      const data = [cascaderValue[0], value]
      changedValue = data
    } else {
      AreaOptions = []
      // 清空 cascaderValue 里市的数据
      const data = [cascaderValue[0]]
      changedValue = data
    }
    // 清空 区 options
    setAreas(AreaOptions)
    // 设置 cascaderValue
    setCascaderValue(changedValue)
    triggerChange(changedValue)
  }
  // 区
  const onAreaChange = (value) => {
    let changedValue = []
    if (!isNil(value)) {
      // 设置区数据
      const data = cascaderValue.slice()
      data[2] = value
      changedValue = data
    } else {
      // 清空 cascaderValue 里区的数据
      const data = [cascaderValue[0], cascaderValue[1]]
      changedValue = data
    }
    setCascaderValue(changedValue)
    triggerChange(changedValue)
  }

  useEffect(() => {
    if (isArray(value) && value.length >= 3) {
      const provinceGeoId = value[0]
      const cityGeoId = value[1]
      const provinceData =
        data.find((item) => [provinceGeoId].includes(item.value)) || {}
      // 设置市数据
      const CityOptions = provinceData.children
      const cityData =
        CityOptions.find((item) => [cityGeoId].includes(item.value)) || {}
      // 设置 区 options
      const AreaOptions = cityData.children
      setCitys(CityOptions)
      setAreas(AreaOptions)
      setCascaderValue(value)
    }
  }, [value])

  return (
    <span className={`${styles.Inf_Province_Select_Cascader} ${width100?styles.width100:''}`}>
      <Select
        suffixIcon={
          <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
        }
        value={cascaderValue[0]}
        onChange={onProvinceChange}
        options={data}
        style={{ width: 90, marginRight: 7 }}
        placeholder={intl.formatMessage({ id: 'ordArsProvince'})}
      ></Select>
      <Select
        suffixIcon={
          <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
        }
        value={cascaderValue[1]}
        onChange={onCityChange}
        options={citys}
        style={{ width: 90, marginRight: 7 }}
        placeholder={intl.formatMessage({ id: 'ordArsCity'})}
      ></Select>
      <Select
        suffixIcon={
          <span className="iconfont iconbtn_a_dpdownmenu select-icon"></span>
        }
        value={cascaderValue[2]}
        onChange={onAreaChange}
        options={areas}
        style={{ width: 90, marginRight: 7 }}
        placeholder={intl.formatMessage({ id: 'ordArsDistrict'})}
      ></Select>
    </span>
  )
}

export default ProvinceSelectCascader
