import {Col, DatePicker, Form, Input, Modal, Row, Select} from 'antd'
import React, {useState} from 'react'
import myMessage from 'components/Message'
import styles from './index.module.less'
import {EmailValidateRule, PhoneValidateRule, RequiredInputRule} from 'utils/ValidateRulesAndMsg'
import { FormattedMessage, useIntl } from 'react-intl'
import {TrimInput, TrimTextArea} from 'components/WithTrim/index'
import ProvinceSelectCascader from '../ProvinceSelectCascader/index'
import data from '../ProvinceSelectCascader/data.json'

const {Option} = Select

const AddressModal = ({
                           visible,
                           onCreate,
                           onCancel,
                           isAddress,
                           thatAddress,
                         }) => {
  const [form] = Form.useForm()
  const intl = useIntl()
  const {TextArea} = Input;
  const address = JSON.parse(JSON.stringify(thatAddress))
  // useState(() => {
    form.setFieldsValue({
      customerName: address.customerName ? address.customerName : '',
      customerPhone: address.phoneNum ? address.phoneNum : '',
      customerWechat: address.customerWechat ? address.customerWechat : '',
      customerEmail: address.email ? address.email : '',
      areaArray: address.province ? [address.province, address.city, address.region] : '',
      customerAddress: address.address ? address.address : '',
    })
  // }, [thatAddress])
  return (
    <Modal
      className={styles.createAddressDialog}
      visible={visible}
      title={isAddress ? <FormattedMessage id="ordNewAddress" /> : <FormattedMessage id="ordArsEdit" />}
      okText={isAddress ? <FormattedMessage id="save" /> : <FormattedMessage id="ordArsUpdate" />}
      cancelText={<FormattedMessage id="cancel" />}
      centered
      cancelButtonProps={{className: 'footerCanceltext'}}
      onCancel={onCancel}
      maskClosable={false}
      width={600}
      forceRender={true}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            if (!values.customerPhone) {
              myMessage.warning(intl.formatMessage({id:'ordPhoneRequired'}))
              return
            }
            const provinceGeoId = values.areaArray[0]
            const cityGeoId = values.areaArray[1]
            const AreaGeoId = values.areaArray[2]
            const provinceData =
              data.find((item) => [provinceGeoId].includes(item.value)) || {}
            // 设置市数据
            const CityOptions = provinceData.children
            const cityData =
              CityOptions.find((item) => [cityGeoId].includes(item.value)) || {}
            // 设置 区 options
            const AreaOptions = cityData.children
            const AreaData=AreaOptions.find((item) => [AreaGeoId].includes(item.value)) || {}
            values.district=provinceData.label+cityData.label+AreaData.label
            form.resetFields()
            onCreate(values)
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="add_project_form"
        requiredMark={false}
        className={styles.addressModal}
        destroyOnClose={true}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="customerName"
              label={<FormattedMessage id="orderRecipient" />}
              rules={[
                {
                  required: true,
                  message: (<FormattedMessage id="required" />),
                },
              ]}
            >
              <Input placeholder={intl.formatMessage({ id: 'needInput'})} maxLength={30}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="customerPhone"
              label={<FormattedMessage id="ordArsPhone" />}
              rules={[{
                required: true,
                message: (<FormattedMessage id="required" />),
              },PhoneValidateRule(intl)]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="customerEmail"
              label="Email"
              rules={[EmailValidateRule(intl)]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name=""
              label={<FormattedMessage id="ordAddress" />}
            >
              <Form.Item
                label=""
                name="areaArray"
                className={styles.Inf_ant_form_item}
              >
                <ProvinceSelectCascader width100={true}/>
              </Form.Item>
              <Form.Item
                style={{display: 'inline-block'}}
                label=""
                name="customerAddress"
                rules={[RequiredInputRule(intl)]}
              >
                <TrimInput style={{width: '520px'}} placeholder={intl.formatMessage({ id: 'ordAddressDetail'})}/>
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default AddressModal
