export const proHost = 'https://csi.infotos.co' //生产环境
export const devHost = 'https://csi.infotos.co' // 开发-带网关
export const userDevHost = 'https://csi.infotos.co' // 用户系统api-带网关
export const storeDevHost = 'https://csi.infotos.co'
// export const devHost = 'http://lxuan.i234.me:8183' // 开发-带网关
// export const userDevHost = 'http://lxuan.i234.me:8183' // 开发-带网关
// export const storeDevHost = 'http://lxuan.i234.me:8191' // 开发-带网关
export const toBC = 'http://127.0.0.1:5173' // 跳转到编辑地址

let host = ''
let userHost = ''
let storeHost = ''

if (process.env.NODE_ENV !== 'production') {
  host = devHost
  userHost = userDevHost
  storeHost=storeDevHost
} else {
  host = proHost
  userHost = proHost
  storeHost=proHost
}

const systemName = '/api/2c' // 开发-无网关
const userSystem = '/api/2c' // 开发-无网关
export const BACKEND_API_URL = host + systemName

export const CENTER_API_URL = userHost + userSystem
//销售重定义
export const BACKEND_STOREAPI_URL = storeHost


const Api = {
  toBC:toBC,
  login: CENTER_API_URL + '/login',
  common: {
    // 公共部分
    getCommonSetting: BACKEND_API_URL + '/common/getCommonSetting', // get 查询当前企业公共设置(企业信息、LOGO、SEO、个人站链接等设置)
  },
  website: {
    getWebSetting: BACKEND_API_URL + '/website/get',
  },
  reservation: {
    // 预约
    getAllService: BACKEND_API_URL + '/reservation/getAllService', // get 查询服务信息列表
    add: BACKEND_API_URL + '/reservation/add', // post 提交预约信息
  },
  showcaseSetting: {
    getAll: BACKEND_API_URL + '/showcaseSetting/getAll', // get 查询展示信息
  },
  getEnterpriseLevel: {
    getEnterpriseLevel: BACKEND_API_URL + '/common/getEnterpriseLevel', // get 查询当前账号权限
  },
  showcasePhoto: {
    getAllShowcaseCategory:
      BACKEND_API_URL + '/showcasePhoto/getAllShowcaseCategory', // get 查询展示分类
    getShowcasePhotoForPage:
      BACKEND_API_URL + '/showcasePhoto/getShowcasePhotoForPage', // get 分页查询展示图片
  },
  project: {
    getProjectCategoryList: BACKEND_API_URL + '/project/getProjectCategoryList', // get 查询图片项目分类
    getProjectInfoList: BACKEND_API_URL + '/project/getProjectInfoList', // get 查询客片集(项目)列表
    getById: BACKEND_API_URL + '/project/getById', // get 查询项目详情
    getProjectInfoForLogin: BACKEND_API_URL + '/project/getProjectInfoForLogin', // get 查询客片集(项目)登录界面信息
    onOffIsOpenShare: BACKEND_API_URL + '/project/onOffIsOpenShare', // post 开关是否公开分享
    onOffIsShareNeedPinCode: BACKEND_API_URL + '/project/onOffIsShareNeedPinCode', // post 开关分享是否密码访问
    onOffBookmarkIsOpenShare: BACKEND_API_URL + '/project/onOffBookmarkIsOpenShare', // post 开关是否公开分享
    onOffBookmarkIsShareNeedPinCode: BACKEND_API_URL + '/project/onOffBookmarkIsShareNeedPinCode', // post 开关分享是否密码访问
    authorization: BACKEND_API_URL + '/project/visitor/authorization/url', // get 获取微信授权地址
    wechat: BACKEND_API_URL + '/project/visitor/wechat/mp', // post 微信公众号通过授权code 获取微信用户信息
    downloadCode: BACKEND_API_URL + '/project/verification/downloadCode', // post 验证项目下载码
  },
  member: {
    login: BACKEND_API_URL + '/member/login', // post 登录
    record: BACKEND_API_URL + '/project/record/browse/add', // post 浏览记录
  },
  customerPhoto: {
    pageList: BACKEND_API_URL + '/customerPhoto/getPage', // get 分页查询客片信息
    photoCategoryList: BACKEND_API_URL + '/customerPhotoCategory/getAll',
    setHide: BACKEND_API_URL + '/customerPhoto/setHide', // post 设置客片隐藏/显示
    addBookmarkPhoto: BACKEND_API_URL + '/customerPhoto/addBookmarkPhoto', // post 添加客片至收藏夹
    downLoadLogin: BACKEND_API_URL + '/customerPhoto/downLoadLogin', // post 下载登录
    getDownloadUrl: BACKEND_API_URL + '/customerPhoto/getDownloadUrl', // get 获取客片图片打包下载地址
    pageListForShare: BACKEND_API_URL + '/customerPhoto/getPageForShare', // get 分页查询客片信息-针对分享
    wechatMp: BACKEND_API_URL + '/project/visitor/wechat/mp', // post 微信登录
    emailLogin: BACKEND_API_URL + '/project/visitor/email/authorization', // post 邮箱登录
    addShare: BACKEND_API_URL + '/projectUserShare/add', // post 添加分享记录
    addMessage: BACKEND_API_URL + '/customerPhoto/addMessage', // post 添加留言
  },
  customerPhotoCategory: {
    getAll: BACKEND_API_URL + '/customerPhotoCategory/getAll', // get 查询客片分类
    getAllCanDownload: BACKEND_API_URL + '/customerPhotoCategory/getAllCanDownload', // get 查询客片允许下载的分类
    getDownloadUrl: BACKEND_API_URL + '/customerPhotoCategory/getDownloadUrl', // post 获取客片分类打包下载地址
    getDownload: BACKEND_API_URL + '/customerPhotoCategory/generate/download', // get 获取客片图片打包下载地址
    getAllForShare: BACKEND_API_URL + '/customerPhotoCategory/getAllForShare', // get 查询客片分类-针对分享
  },
  bookmark: {
    getAll: BACKEND_API_URL + '/bookmark/getAll', // get 查询收藏夹
    getAllForDownload: BACKEND_API_URL + '/bookmark/getAllForDownload', // get 查询收藏夹 - 含下载信息
    getDownloadUrl: BACKEND_API_URL + '/bookmark/getDownloadUrl', // post 获取收藏夹打包下载地址
    getAllForShare: BACKEND_API_URL + '/bookmark/getAllForShare', // get 查询收藏夹分类-针对分享
    paymentTip: BACKEND_API_URL + '/bookmark/paymentTip', //  post 推送b端收藏夹提示
    getDownload: BACKEND_API_URL + '/bookmark/generate/download', // post 收藏夹打包
  },
  bookmarkPhoto: {
    addMessage: BACKEND_API_URL + '/bookmarkPhoto/addMessage', // post 添加留言
    cancel: BACKEND_API_URL + '/bookmarkPhoto/cancel', // post 取消收藏
    getListForPage: BACKEND_API_URL + '/bookmarkPhoto/getListForPage', // get 分页查询收藏夹图片
    getMessage: BACKEND_API_URL + '/bookmarkPhoto/getMessage', // get 获取单个收藏图片留言
    setHide: BACKEND_API_URL + '/bookmarkPhoto/setHide', // post 设置收藏夹图片隐藏、显示
    surePhoto: BACKEND_API_URL + '/bookmarkPhoto/surePhoto', // post 提交收藏夹下的图片
    getDownloadUrl: BACKEND_API_URL + '/bookmarkPhoto/getDownloadUrl', // get 获取收藏夹图片打包下载地址
    pageListForShare: BACKEND_API_URL + '/bookmarkPhoto/getPageForShare', // get 分页查询客片信息-针对分享
  },

  product:{
    //通用查询接口
    toSupplier: BACKEND_API_URL + '/supplier/currency/request',
    //通用查询接口
    toStore: BACKEND_API_URL + '/store/currency/request',
    //master通用接口
    toMasterCurrency: BACKEND_API_URL + '/store/master/currency/request',
    //根据场景ID获取场景信息
    getStoreId: BACKEND_API_URL + '/project/scene/info',
    //查询项目详情
    getProject: BACKEND_API_URL + '/project/getById',
    //查询导出排版信息
    getInformation: BACKEND_API_URL + '/export/information/get',
    //查询购物车
    getShoppingCarList: BACKEND_API_URL + '/shopping/getShoppingCarList',
    //移除购物车商品
    cancelShoppingCar: BACKEND_API_URL + '/shopping/cancelShoppingCar',
    //导出排版信息失效
    invalidInformation: BACKEND_API_URL + '/export/information/update/invalid',
    //供应商通用查询接口
    storeCurrency: BACKEND_API_URL + '/store/currency/request',
    //添加商品到购物车
    addShoppingCar: BACKEND_API_URL + "/shopping/addShoppingCar",
    //添加导出排版信息
    addInformation: BACKEND_API_URL + "/export/information/add",

    //添加画册
    addAblum:BACKEND_STOREAPI_URL +"/album-service/album/add",

    // 产品
    getPricesheetId: BACKEND_STOREAPI_URL +'/store-service/product/price-list/get-default', //获取价格表默认id
    // getPricesheetId: BACKEND_STOREAPI_URL +'/store-service/product/price-list/get-default', //获取价格表默认id
    getEnterprise: BACKEND_STOREAPI_URL +'/store-service/product/price-list/get-enterprise', //获取供应商表

    pricesheetInfo: 'product/product-base-info/get-list', //获取价格表详细信息
    getPolicy: 'policy/get', //通过商家id获取政策信息

    //模板通用查询接口
    toMod: BACKEND_API_URL + '/mod/data/currency/request',
    //查询导出图片基本信息
    getExportInfo:'export-info/get/exportInfo',
    //添加购物车或购买删除该编辑中产品
    deleteCart:'export-info/delete',
    //查询已领取的优惠券
    receivedCoupon:'coupon-detail/get-list',
    //领取优惠券
    getCoupon:'coupon-detail/get-coupon',
    //获取设为推送的优惠券
    getPushCoupon:'coupon/get-push', 

    //获取收货地址
    getAddress:'order/address/get',
    //添加收货地址
    addAddress:"order/address/add",
    //修改收货地址
    editAddress:"order/address/edit",
    //删除收货地址
    deleteAddress:"order/address/delete",
    //添加订单
    addOrder:"order/order-info/add",
    //获取订单详细信息
    orderDetails:"order/order-info/get",
    //获取快递公司信息
    logistEnu:"dict/logist-enu",
    //获取订单信息
    getAllorder:"order/order-info/get-cus",
    //支付订单信息
    payorder:"order/order-info/unifiedOrder/v3",
    //取消订单信息
    cancelorder:"order/order-info/cancel",
    //删除订单信息
    deleteorder:"order/order-info/delete",
    //获取订单支付状态是否成功
    getPayStatus:"pay/get",

  },
}

export default Api
